<template>
  <div id="mydiv">
    <mt-header fixed :title="(querytype==1?'两癌筛查':'孕优')+'报告登录'">
      <mt-button slot="left" style=" width: 90px"
                 @click="back()"      icon="back">返回</mt-button>

    </mt-header>

<br><br><br><br><br>
    <!-- 提醒栏 -->
    <div class="imgDiv">
      <span class="title">请输入预留的姓名及证件</span>
    </div>
    <!-- 操作区域 -->
    <div class="operateDiv">
      <!-- 姓名 -->
      <mt-field     :placeholder="accountPlaceholder"
          v-model="form.Xingming"      class="myinput"
      ></mt-field>
      <!-- 身份证号 -->
      <mt-field
          placeholder="身份证号"
          v-model="form.Shenfenzheng"
          class="myinput"
      ></mt-field>
      <div>
        <mt-button
            size="large"
            class="mybutton"
            @click.native="login"
        >登录</mt-button>
      </div>
    </div>

  </div>
</template>

<script>
import {Toast,Indicator} from 'mint-ui';

import DialogUtil from "@/common/DialogUtil";
// import axios from "axios";
export default {
  name: "page-badge",
  data() {
    return {
      accountPlaceholder: "姓名",
      // 是否是用户账户登录，默认是 true
      querytype:'',
      form: {
        Xingming: '',//'张秀芝',
        Shenfenzheng: '',//'370306195909133528',
        Leixing: ''
      }
    };
  },

  created(){
   this.querytype=this.$route.query.type
    this.form.Leixing=this.querytype;

   //Toast( this.querytype)
  },
  methods: {
    back() {
      this.$router.go(-1);
    },

    // 登录显示模态框 调用 mint-ui 的 messagebox
    login(){

      let that = this;
      Indicator.open({text:'加载...',spinnerType:'fading-circle'})
      let hisurl='/api/screendisplay/YYT_Report_DataSearch'
      //let hisurl='http://120.224.143.122:8098/LASCdataShow.dll/hospital/interface/YYT_Report_DataSearch'
     // let hisurl='http://8.140.140.112:6060/api/kanbaninfo/getconfigdata'
      this.$axios.post(hisurl,that.form).then((res) => {
        let code=res.data.code;

        let respData = res.data.data
    //    Toast(JSON.stringify( respData))
       // console.log(res);
        //Toast(res);
        let data = [];
        for (let i in respData) {
          data.push({
            Xingming: this.form.Xingming,
            Shenfenzheng: this.form.Shenfenzheng,
            Leixing: this.form.Leixing,
            RenyuanBM: res.data.data[i].RenyuanBM,
            JiandangRQ: res.data.data[i].JiandangRQ
          })
        }
        Indicator.close()
       // Toast(code);
        if (code==='10001')
          this.$router.push({ path:'/hospital/report/rptlist', query:{data:JSON.stringify( data),type:that.querytype,rn:DialogUtil.randomn(8)}});
        else {

          Toast('登录失败:'+res.data.msg);
        }
      }).catch(err => {
        Indicator.close()
        Toast(JSON.stringify( err));
      }).finally(() => {
        Indicator.close()
      })

      }
    }


};
</script>

<style lang="css">
.page-badge-container {
  padding: 0 10px;
}

/* 你需要登录才能继续仿问的布局样式 */
.tipslogin {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px;
  background: rgb(238, 238, 238);
  padding-left: 20px;
  padding-right: 20px;
}
/* 半闭样式 */
.closeSpan {
  color: rgb(153, 153, 153);
}
/* 图片 logo 根样式 */
.imgDiv {
  display: flex;
  justify-content: center;
}
/* 图片样式 */
.logo {
  width: 80px;
  height: 80px;
}
/* 上面的线隐藏 */
.mint-cell-wrapper {
  background-image: linear-gradient(180deg, #fff, #fff 0%, transparent 0%);
}
/* 输入框底边框样式 */
.mint-cell-wrapper {
  border: 1px solid #495949;
}

/* 修改按钮按钮下的默认颜色 */
.mint-button::after {
  background-color: transparent;
}
/* 操作区域的样式*/
.operateDiv {
  padding-left: 20px;
  padding-right: 20px;
}
/* 输入框边距 */
.myinput {
  margin-top: 30px;
}
/* 获取短信验证码字体颜色 */
.getCode {
  color: #ff5000;
  font-size: 17px;
  border-left: 1px solid #b5b5b5;
  padding-left: 7px;
}
/* 免费注册根样式 */
.registerDiv {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* 免费注册字体样式*/
.registerDiv span {
  color: #555;
}
/* 登录短信验证按钮边距 */
.mybutton {
  margin-top: 20px;
}
/* 修改默认 butoon 的样式 达到和淘宝登录一样 */
.mint-button--default {
  /*background: linear-gradient(to right, #ff00ff, 5%,#ffee00);*/
  /*border-radius: 25px;*/
  /*color: #fff !important;*/
  /*height: 48px;*/
  background-color: #eb89a9;
  color: #fff;
 border-radius: 10%;
  marginTop: 50px;
  marginBottom: 40px;
  border: #eb89a9;
}
/* 同上 */
.mint-button--primary {
  border-radius: 25px;
  height: 48px;
  background: linear-gradient(to right, #2dbcfe, 5%, #6e7b55);
  border: 1px solid #ff5000;
  color: #ff5000;
}
/* 修改默认弹框的样式，达到基本和淘宝的效果一样 */
.mint-msgbox {
  border-radius: 8px;
  width: 70%;
}
/* 弹出框内容样式 */
.mint-msgbox-content {
  min-height: 50px;
  font-size: 18px;
}
/* 弹出内容居中 */
.mint-msgbox-message {
  line-height: 50px;
}
/* 弹出框确定按钮样式 */
.mint-msgbox-btns {
  height: 60px;
}
/* 弹出框确定按钮字体 */
.mint-msgbox-confirm {
  color: #f40;
}

.mint-header {
  font-size: 18px;
  background-color: #eb89a9;
}
</style>